const links = [
  {
    label: "Github",
    url: "https://github.com/benits",
  },
  {
    label: "Linkedin",
    url: "https://www.linkedin.com/in/omatheusbenites",
  },
  {
    label: "Twitter",
    url: "https://twitter.com/omatheusbenites",
  },
  {
    label: "Youtube",
    url: "https://www.youtube.com/c/omatheusbenites",
  },
]

export default links
