const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Sobre Mim",
    url: "/about",
  },
  {
    label: "Gitfolio",
    url: "/gitfolio",
  },
  {
    label: "Hands-on",
    url: "/hands-on",
  },
  {
    label: "Assine",
    url: "/newsletter",
  },
]

export default links
